import {
  ChangeEvent,
  forwardRef,
  InputHTMLAttributes,
  MouseEventHandler,
  ReactElement,
} from "react";
import cn from "classnames";

import styles from "@platformAssets/components/InputGlobal.module.scss";

type Props = {
  name: string;
  value?: InputHTMLAttributes<HTMLInputElement>["value"];
  handleChange: (event: ChangeEvent<HTMLInputElement>) => void;
  id?: string;
  className?: string;
  animatePlaceholder?: boolean;
  placeholder?: string | null;
  autoComplete?: string;
  errorText?: string;
  warningText?: string;
  disabled?: boolean;
  error?: boolean;
  warning?: boolean;
  leftIconSrc?: ReactElement;
  rightIconSrc?: ReactElement;
  extraIconSrc?: ReactElement;
  passwordShowIcon?: boolean;
  type?: string;
  handleClickLeftIcon?: MouseEventHandler<HTMLDivElement>;
  handleClickRightIcon?: MouseEventHandler<HTMLDivElement>;
  handleClickExtraIcon?: MouseEventHandler<HTMLDivElement>;
  onFocus?: () => void;
  onBlur?: () => void;
  onClick?: () => void;
};

const InputGlobal = forwardRef<HTMLInputElement, Props>(
  (
    {
      animatePlaceholder = false,
      autoComplete = "off",
      className = "",
      disabled = false,
      error = false,
      errorText = "",
      extraIconSrc,
      handleChange,
      handleClickExtraIcon,
      handleClickLeftIcon,
      handleClickRightIcon,
      id,
      leftIconSrc,
      rightIconSrc,
      name,
      onBlur,
      onFocus,
      onClick,
      passwordShowIcon,
      placeholder = null,
      type = "text",
      value,
      warningText = "",
    },
    ref
  ) => {
    return (
      <div
        className={cn(styles.inputWrapper, className, {
          [styles.inputAnimated]: animatePlaceholder,
          [styles.hasIconLeft]: leftIconSrc,
          [styles.hasIconRight]: rightIconSrc,
          [styles.hasIconExtra]: extraIconSrc,
        })}
      >
        <div className={styles.inputWrapperRelative}>
          {leftIconSrc && (
            <div
              className={`${styles.iconLeft} ${
                handleClickLeftIcon ? styles.iconLeftActive : ""
              }`}
              onClick={handleClickLeftIcon}
            >
              <div className={styles.img}>{leftIconSrc}</div>
            </div>
          )}
          <input
            className={cn({
              [styles.disabled]: disabled,
              [styles.errorDisabled]: disabled && error,
              [styles.error]: !disabled && (error || errorText),
            })}
            type={type}
            name={name}
            aria-label={name}
            id={id ?? `${name}Id`}
            autoComplete={autoComplete}
            placeholder={placeholder ?? ""}
            disabled={disabled}
            value={value ?? ""}
            onChange={handleChange}
            ref={ref}
            onFocus={() => {
              if (typeof onFocus === "function") {
                onFocus();
              }
            }}
            onBlur={() => {
              if (typeof onBlur === "function") {
                onBlur();
              }
            }}
            onClick={() => {
              if (typeof onClick === "function") {
                onClick();
              }
            }}
          />
          {animatePlaceholder && (
            <label
              htmlFor={id ?? `${name}Id`}
              className={styles.inputAnimatedPlaceholder}
            >
              {placeholder}
            </label>
          )}

          {extraIconSrc && (
            <div
              className={cn(styles.iconExtra, {
                [styles.iconExtraActive]: handleClickExtraIcon,
              })}
              onClick={handleClickExtraIcon}
            >
              <div className={styles.img}>{extraIconSrc}</div>
            </div>
          )}
          {rightIconSrc && (
            <div
              className={cn(styles.iconRight, {
                [styles.iconRightActive]: handleClickRightIcon,
                [styles.iconShow]: passwordShowIcon,
              })}
              onClick={handleClickRightIcon}
            >
              <div className={styles.img}>{rightIconSrc}</div>
            </div>
          )}
        </div>
        <p className={styles.errorText}>{errorText}</p>
        <p className={styles.errorWarning}>{warningText}</p>
      </div>
    );
  }
);

export default InputGlobal;
